import { useState } from "react";
import Lottie from "lottie-react";
import { IoMail } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import { RiWhatsappFill } from "react-icons/ri";
import coffeeCup from "../Assets/coffee-cup.json";
import coffee from "../Assets/waiting-coffee.json";
import LoadingBar from "react-top-loading-bar";

export const Contact = () => {
  const [startingAnimation, setStartingAnimation] = useState(true);
  const [pageState, setPageState] = useState(false);
  const [progress, setProgress] = useState(100);

  const pageContentToggle = () => {
    setTimeout(() => {
      setPageState(true);
      setStartingAnimation(false);
    }, 1000);
  };

  return (
    <div data-attr="contact-page" data-page={pageState} onLoad={pageContentToggle()} data-barba="wrapper" data-barba-namespace="contact">
      {/* seo */}
      <Helmet>
        <title>Neyaz Nafiz | Contact</title>
        <meta name="description" content="Contact with Neyaz Nafiz. Here you will find the contact information of Neyaz and you will be able to directly contact with Neyaz." />
        <link rel="canonical" href="https://neyaznafiz.xyz/contact" />
      </Helmet>

      <LoadingBar
        color="#292524"
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      {/* Starting Animation */}
      <div
        id="starting-animation"
        data-starting-animation={startingAnimation}
        className="absolute top-0 h-screen w-screen bg-[var(--primary-color)] z-[9999] flex items-center justify-center"
      >
        <Lottie animationData={coffeeCup} className="h-[296px]" />
      </div>

      {/* Page Content */}
      <div className="content-wrapper overflow-hidden h-screen sm:h-[calc(100vh-108px)]">
        <div className="sm:border-b-2 border-[var(--secondary-color)]  pb-2 h-fit max-w-[750px] w-full m-auto">
          {/* text */}
          <div className="text-center px-2 tracking-wide w-fit mx-auto">
            <h2 className="max-w-[571px] font-semibold">
              <span className="uppercase">The best way to get in touch is to either send me an</span>
              <b> E-mail </b>
              <span className="uppercase">or DM me on</span>
              <b> WhatsApp.</b>
            </h2>
          </div>

          <a href="https://buymeacoffee.com/neyaznafiz" target="_blank" rel="noreferrer" className="mx-auto block w-fit mt-12 mb-4"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className="buymeacoffee-btn" /></a>

          <script type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="neyaznafiz" data-color="#FFDD00" data-emoji=""  data-font="Cookie" data-text="Buy me a coffee" data-outline-color="#000000" data-font-color="#000000" data-coffee-color="#ffffff" ></script>

          <div className="flex md:flex-row flex-col justify-center items-center md:items-end gap-2 md:gap-12">
            {/* coffee */}
            <div className="">
              <Lottie animationData={coffee} className="h-24 w-24 mx-auto" />
              {/* text */}
              <p className="text-paragraph uppercase">I am here for you</p>
            </div>

            {/* links */}
            <div className="space-y-3">
              {/* wp */}
              <a
                href="https://wa.me/+8801303246616"
                target="_blank"
                rel="noreferrer"
                className="flex items-center hover:text-yellow-600 mt-4 transition-all duration-700 relative z-[5]"
              >
                <span className="text-2xl mr-2">
                  <RiWhatsappFill />
                </span>
                <span className=" text-lg font-semibold tracking-wide"> WhatsApp</span>
              </a>

              {/* mail */}
              <a
                href="mailto:neyaznafiz@outlook.com"
                className="font-normal flex justify-center items-center hover:text-yellow-600 transition-all duration-700 relative z-[5]"
              >
                <span className=" text-2xl mr-2"><IoMail /></span>
                <span className="text-lg font-semibold tracking-wide">
                  neyaznafiz@outlook.com
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
