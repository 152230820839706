import React from 'react'
import './Strategy.css'
import { useInView } from 'react-intersection-observer';

export const Strategy = () => {
  const { ref, inView } = useInView({ rootMargin: '100px', threshold: 0, });

  return (
    <div data-attr="strategy" className="sm:mt-20 my-10">
      <div ref={ref} className="mx-auto max-w-[1300px] px-4 relative">

        <div className={`title-wrapper`}>
          <h1 className={`title cinzel delay-1000 transition-all duration-[1000ms] ease ${inView ? 'opacity' : 'opacity-0'}`}><span className='first-letter'>S</span>TRaTegY</h1>
        </div>

        <div className='point-wrapper'>

          {/* 1 */}
          <div className={`single-point delay-200 transition-all duration-[1000ms] ease ${inView ? 'opacity-100' : 'opacity-0'}`}>
            <p className='serial-number cinzel'>1</p>
            <p className='text'>
              <span className='cinzel font-bold'>Collaborative Design </span>
              <span>I closely collaborate with you to understand your vision and needs, ensuring your website is tailored precisely to your goals.</span>
            </p>
          </div>

          {/* 2 */}
          <div className={`single-point delay-300 transition-all duration-[1000ms] ease ${inView ? 'opacity-100' : 'opacity-0'}`}>
            <p className='serial-number cinzel'>2</p>
            <p className='text'>
              <span className='cinzel font-bold'>Brand Integration </span>
              <span>Your brand's identity guides every aspect of my design, creating a seamless online presence.</span>
            </p>
          </div>

          {/* 3 */}
          <div className={`single-point delay-500 transition-all duration-[1000ms] ease ${inView ? 'opacity-100' : 'opacity-0'}`}>
            <p className='serial-number cinzel'>3</p>
            <p className='text'>
              <span className='cinzel font-bold'>Efficiency </span>
              <span>Efficiency I work swiftly without compromising quality, getting your project online faster.</span>
            </p>
          </div>

          {/* 4 */}
          <div className={`single-point delay-700 transition-all duration-[1000ms] ease ${inView ? 'opacity-100' : 'opacity-0'}`}>
            <p className='serial-number cinzel'>4</p>
            <p className='text'>
              <span className='cinzel font-bold'>Transparency </span>
              <span>Open communication and regular updates keep you informed and your feedback shapes the final product.</span>
            </p>
          </div>

          {/* 5 */}
          <div className={`single-point delay-200 transition-all duration-[1000ms] ease ${inView ? 'opacity-100' : 'opacity-0'}`}>
            <p className='serial-number cinzel'>5</p>
            <p className='text'>
              <span className='cinzel font-bold'>Personalized Solutions </span>
              <span>Your unique requirements drive my design and developing process, delivering a product that reflects your vision.</span>
            </p>
          </div>

          {/* 6 */}
          <div className={`single-point delay-200 transition-all duration-[1000ms] ease ${inView ? 'opacity-100' : 'opacity-0'}`}>
            <p className='serial-number cinzel'>6</p>
            <p className='text'>
              <span className='cinzel font-bold'>User-Centric Design </span>
              <span>I prioritize the user experience, making sure your project resonates with your target audience.</span>
            </p>
          </div>

        </div>
      </div>
    </div>
  )
}
