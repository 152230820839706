import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// document.addEventListener("contextmenu", (event) => {
//   event.preventDefault()
// }, false)

let customCursor = document.getElementById("custom-cursor");
document.addEventListener("mouseenter", () => { 
  customCursor.style.display = "block";
})
document.addEventListener("mouseleave", () => { 
  customCursor.style.display = "none";
})

document.addEventListener("touchend", () => {
  customCursor.style.display = "none";
})

let clientYAxis = 0;
let clientXAxis = 0;
document.addEventListener("mousemove", (event) => {
  clientYAxis = event.clientY
  clientXAxis = event.clientX
  customCursor.style.transform = `translate(${clientXAxis}px, ${clientYAxis + window.scrollY}px)`
})

// let scrollDirection = false
// window.onscroll = function(e) {
//   // print "false" if direction is down and "true" if up
//   scrollDirection = this.oldScroll > this.scrollY
//   this.oldScroll = this.scrollY;
// }


// let value = 19
// let bgValue = 100
// document.addEventListener("scroll", (event) => {
//   customCursor.style.transform = `translate(${clientXAxis}px, ${clientYAxis + window.scrollY}px)`
//   document.body.setAttribute("style", `background-position-y: ${value}%; background-size: ${bgValue}%`)


//   if (scrollDirection === false) {
//     // if (value !== 88) value = value + 0.50
//     if(bgValue !== 200 ) bgValue = bgValue + 0.50
//     } 
//   else {
//     // if (value !== 19) value = value - 0.50
//     if(bgValue !== 100) bgValue = bgValue - 0.50
//   }
// })