import React from "react";
import { Route, Routes } from "react-router-dom";
import { Contact, Portfolio, Blog, NotFound } from "./Pages";



function Router() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Portfolio />} />
        <Route path="/neyaz-posts" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Router;
